<template>
    <v-form v-model="valid">
        <v-card
            dark
            style="background-color: transparent; padding: 0px"
            elevation="0"
            v-bind="$attrs"
            :disabled="false"
            @keypress.enter="save"
        >
            <v-card-text style="padding: 0px">
                <v-text-field
                    ref="address-line1"
                    v-model="address.line1"
                    v-bind="$attrs"
                    dense
                    outlined
                    :rules="[formRules.required]"
                    label="Address Line 1"
                />
                <v-text-field
                    ref="address-line2"
                    v-model="address.line2"
                    dense
                    outlined
                    v-bind="$attrs"
                    label="Address Line 2"
                />
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-autocomplete
                            ref="country"
                            v-model="address.country"
                            v-bind="$attrs"
                            :items="countries"
                            outlined
                            dense
                            hide-details="auto"
                            item-text="name"
                            item-value="countryCode"
                            label="Country"
                            placeholder="Country"
                            :rules="[formRules.required]"
                            @change="countryChanged"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-autocomplete
                            ref="state"
                            v-model="address.state"
                            v-bind="$attrs"
                            item-text="name"
                            outlined
                            dense
                            hide-details="auto"
                            item-value="stateCode"
                            :loading="statesLoading"
                            :disabled="statesLoading || !address.country"
                            :items="stateProvinces"
                            label="State/Province/Region"
                            :rules="[formRules.required]"
                            placeholder="State"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            ref="city"
                            v-model="address.city"
                            outlined
                            dense
                            v-bind="$attrs"
                            :rules="[formRules.required]"
                            label="City"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            ref="zip"
                            v-model="address.postalCode"
                            outlined
                            dense
                            v-bind="$attrs"
                            :rules="[formRules.required]"
                            label="ZIP / Postal Code"
                        />
                    </v-col>
                </v-row>
                <!-- <base-btn
                    v-if="view === 'update'"
                    class="elevation-0 dialog-btns"
                    style="float: right"
                    @click="submitAddress"
                >
                    Submit
                </base-btn>
                <base-btn
                    v-if="view === 'create'"
                    class="elevation-0 dialog-btns"
                    style="float: right"
                    :disabled="!orgName"
                    @click="submitAddress"
                >
                    Submit
                </base-btn> -->
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
import { mdiClose } from '@mdi/js'
import formRules from '@/utils/form_rules'

export default {
    name: `OrgAddress`,
    props: {
        currentLevel: {
            type: Object,
            required: true
        },
        view: {
            type: String,
            required: true
        },
        orgName: {
            type: String,
            required: false,
            default: () => ''
        },
        label: {
            type: String,
            default: () => `Address`
        }
    },
    data() {
        return {
            formRules,
            valid: null,
            statesLoading: false,
            citiesLoading: false,
            mdiClose,
            address: null
        }
    },
    computed: {
        countries() {
            return this.$store.getters[`location/getCachedCountries`]
        },

        cities() {
            return this.$store.getters[`location/getCachedCities`]({
                countryCode: this.address.country,
                stateCode: this.address.state
            })
        },
        stateProvinces() {
            return this.$store.getters[`location/getCachedStates`]({
                countryCode: this.address.country
            })
        }
    },
    watch: {
        valid(value) {
            this.$emit('valid', value)
        },
        address: {
            deep: true,
            handler() {
                this.submitAddress()
            }
        },
        currentLevel: {
            immediate: true,
            handler() {
                this.address = {
                    city: this.currentLevel.address
                        ? this.currentLevel.address.city
                        : null,
                    country: this.currentLevel.address
                        ? this.currentLevel.address.country
                        : null,
                    line1: this.currentLevel.address
                        ? this.currentLevel.address.line1
                        : null,
                    line2: this.currentLevel.address
                        ? this.currentLevel.address.line2
                        : null,
                    postalCode: this.currentLevel.address
                        ? this.currentLevel.address.postalCode
                        : null,
                    state: this.currentLevel.address
                        ? this.currentLevel.address.state
                        : null
                }
            }
        }
    },

    async mounted() {
        await this.$store.dispatch(`location/cacheStates`, {
            countryCode: this.address.country
        })
    },

    methods: {
        submitAddress() {
            this.$emit('address', this.address)
        },
        async countryChanged(v) {
            if (v) {
                this.statesLoading = true
                try {
                    await this.$store.dispatch(`location/cacheStates`, {
                        countryCode: v
                    })
                    this.statesLoading = false
                } catch {
                    this.statesLoading = false
                }
            } else this.address.state = ``
        },

        async getCities(event) {
            this.citiesLoading = true
            try {
                const [countryCode, stateCode] = event
                await this.$store.dispatch(`location/cacheCities`, {
                    countryCode,
                    stateCode
                })
            } catch {
                this.citiesLoading = false
            } finally {
                this.citiesLoading = false
            }
        }
    }
}
</script>
