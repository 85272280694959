<template>
    <div>
        {{ address.line1 }}
        <span v-if="address.line2"> {{ address.line2 }} </span>,
        {{ address.city }}
        {{ address.state }}
        {{ address.postalCode }}
    </div>
</template>

<script>
export default {
    name: 'PrintOrganizationAddress',
    props: {
        address: {
            type: Object,
            required: true
        }
    }
}
</script>
