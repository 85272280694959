<template>
    <v-container>
        <base-hero-simple class="mb-2">
            <base-router-links :items="links" />
        </base-hero-simple>
        <v-overlay :absolute="true" :value="loading || $apollo.loading">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </v-overlay>
        <v-overlay :absolute="true" opacity="1" :value="noBranches">
            <div
                style="
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                "
            >
                <h2
                    >It looks like you've reached this page by accident. Please
                    click <a @click="$router.push({ name: 'home' })">here</a> to
                    go back to the homepage.</h2
                >
            </div>
        </v-overlay>
        <div class="container-bg" style="margin: auto">
            <div class="container-fg">
                <div style="display: flex; flex-direction: row">
                    <v-row>
                        <v-col cols="10">
                            <div style="display: flex; flex-direction: row">
                                <img
                                    style="width: 130px; margin-top: 10px"
                                    src="../../../assets/logos/getac-logo.svg"
                                />
                                <div
                                    class="text-h7 nav-font pl-3"
                                    style="margin-top: 22px"
                                    >ACCOUNT ORGANIZATIONS</div
                                >
                            </div>
                        </v-col>
                        <v-col cols="2">
                            <!-- <v-select
                                v-if="checkAdmin(currentLevel)"
                                v-model="currentView"
                                :items="viewOptions"
                                dark
                                outlined
                            ></v-select> -->
                        </v-col>
                    </v-row>
                </div>
                <v-divider style="margin-bottom: 1%; border-color: gray" />
                <div v-if="currentView === 'Table View'" class="org-container">
                    <v-row>
                        <v-col cols="12">
                            <v-expansion-panels
                                v-model="panel"
                                class="expanded-panels"
                                dark
                                accordion
                                mandatory
                            >
                                <v-expansion-panel>
                                    <v-expansion-panel-header
                                        class="expansion-header-first"
                                        expand-icon=""
                                    >
                                        <v-col
                                            cols="4"
                                            style="text-align: center"
                                        >
                                            <v-tooltip
                                                v-if="
                                                    currentLevel.parentOrgId &&
                                                    baseLevelId !==
                                                        currentLevel.baseAccountId
                                                "
                                                bottom
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs
                                                    }"
                                                >
                                                    <div
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @click="
                                                            currentLevel.baseAccountId =
                                                                currentLevel.parentOrgId
                                                        "
                                                    >
                                                        <v-list-item-subtitle
                                                            style="
                                                                color: gray;
                                                                margin-bottom: 10px;
                                                            "
                                                        >
                                                            Previous Branch
                                                        </v-list-item-subtitle>
                                                        <v-icon
                                                            color="#db5120"
                                                            >{{
                                                                mdiArrowLeft
                                                            }}</v-icon
                                                        >
                                                    </div>
                                                </template>
                                                <span
                                                    >{{
                                                        currentLevel.parentOrgName
                                                    }}
                                                </span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col
                                            cols="4"
                                            style="text-align: center"
                                            ><v-list-item-subtitle
                                                style="
                                                    color: gray;
                                                    margin-bottom: 5px;
                                                "
                                            >
                                                Organization
                                            </v-list-item-subtitle>
                                            <h1
                                                style="
                                                    font-size: 36px;
                                                    letter-spacing: -0.01rem;
                                                "
                                            >
                                                {{
                                                    currentLevel.baseAccountName
                                                }}
                                            </h1>
                                            <v-list-item-subtitle
                                                v-if="currentLevel.address"
                                                class="mt-2"
                                                style="
                                                    color: gray;
                                                    margin-bottom: 5px;
                                                "
                                            >
                                                <print-org-address
                                                    :address="
                                                        currentLevel.address
                                                    "
                                                />
                                            </v-list-item-subtitle>
                                        </v-col>
                                        <v-col
                                            cols="4"
                                            style="text-align: right"
                                        >
                                            <update-org
                                                v-if="checkAdmin(currentLevel)"
                                                :current-level="currentLevel"
                                                :table-view="tableView"
                                                :delete-only="false"
                                                @updated="updateData"
                                                @deleted="deletedOrg($event)"
                                            ></update-org>
                                        </v-col>
                                    </v-expansion-panel-header>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            v-if="
                                checkAdmin(currentLevel) &&
                                currentLevel.enterprise
                            "
                            cols="12"
                        >
                            <div class="org-users-section">
                                <v-navigation-drawer
                                    width="100%"
                                    dark
                                    permanent
                                    floating
                                >
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-row>
                                                <v-col cols="4">
                                                    <v-list-item-subtitle
                                                        style="margin-top: 7px"
                                                    >
                                                        Organizations Under
                                                        {{
                                                            currentLevel.baseAccountName
                                                        }}
                                                    </v-list-item-subtitle>
                                                </v-col>
                                                <v-col cols="4"> </v-col>
                                                <v-col
                                                    cols="4"
                                                    style="text-align: right"
                                                >
                                                    <create-sub-org
                                                        :no-parent-org="false"
                                                        :current-level="
                                                            currentLevel
                                                        "
                                                        :table-view="tableView"
                                                        @created="updateData"
                                                    ></create-sub-org>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-navigation-drawer>
                                <v-data-table
                                    style="background-color: transparent"
                                    dark
                                    class="org-data-table"
                                    :items="currentLevel.childrenOrg"
                                    hide-default-footer
                                    :no-data-text="`No sub-organizations exist under ${currentLevel.baseAccountName}`"
                                    :headers="subOrgHeaders"
                                    disable-sort
                                >
                                    <template v-slot:item.address="{ item }">
                                        <div v-if="item.address">
                                            <print-org-address
                                                :address="item.address"
                                            />
                                        </div>
                                    </template>
                                    <template v-slot:item.action="{ item }">
                                        <v-btn
                                            color="primary"
                                            elevation="0"
                                            outlined
                                            small
                                            @click="
                                                currentLevel.baseAccountId =
                                                    item.orgId
                                            "
                                            >View</v-btn
                                        >
                                    </template>
                                </v-data-table>
                            </div>
                        </v-col>

                        <v-col cols="7">
                            <div class="org-users-section">
                                <v-navigation-drawer
                                    width="100%"
                                    dark
                                    permanent
                                    floating
                                >
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-row>
                                                <v-col cols="4">
                                                    <v-list-item-subtitle
                                                        style="margin-top: 7px"
                                                    >
                                                        User Accounts
                                                    </v-list-item-subtitle>
                                                </v-col>
                                                <v-col cols="4"> </v-col>
                                                <v-col
                                                    cols="4"
                                                    style="text-align: right"
                                                >
                                                    <add-user
                                                        v-if="
                                                            checkAdmin(
                                                                currentLevel
                                                            )
                                                        "
                                                        :current-level="
                                                            currentLevel
                                                        "
                                                        :table-view="tableView"
                                                        @added="updateData"
                                                    ></add-user>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-navigation-drawer>

                                <v-data-table
                                    style="background-color: transparent"
                                    dark
                                    class="org-data-table"
                                    no-data-text="No accounts have been added under this organization"
                                    :items="currentLevel.accounts"
                                    :headers="userHeaders"
                                    disable-sort
                                >
                                    <template v-slot:item.email="{ item }">
                                        <span v-if="item.account">{{
                                            item.account.email
                                        }}</span>
                                        <span v-if="!item.account">
                                            {{ item.requestedEmail }}</span
                                        >
                                        <v-chip
                                            v-if="item.admin"
                                            class="ma-2"
                                            color="orange"
                                            label
                                            small
                                        >
                                            Admin
                                            <v-icon>{{
                                                mdiAccountSupervisor
                                            }}</v-icon>
                                        </v-chip>
                                        <v-chip
                                            v-if="!item.account"
                                            class="ma-2"
                                            color="gray"
                                            label
                                            small
                                        >
                                            Pending
                                        </v-chip>
                                    </template>
                                    <template v-slot:pending="{ item }">
                                    </template>
                                    <template v-slot:item.action="{ item }">
                                        <update-user
                                            v-if="
                                                item.account &&
                                                checkAdmin(currentLevel)
                                            "
                                            :current-level="currentLevel"
                                            :current-account="item"
                                            @updated="updateData($event)"
                                        ></update-user>
                                        <remove-pending
                                            v-if="
                                                !item.account &&
                                                checkAdmin(currentLevel)
                                            "
                                            :current-level="currentLevel"
                                            :current-account="item"
                                            :table-view="tableView"
                                            @deleted="updateData($event)"
                                        ></remove-pending>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-col>
                        <v-col cols="5">
                            <div class="org-users-section">
                                <v-navigation-drawer
                                    width="100%"
                                    dark
                                    permanent
                                    floating
                                >
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-row>
                                                <v-col cols="4">
                                                    <v-list-item-subtitle
                                                        style="margin-top: 7px"
                                                    >
                                                        Admins
                                                    </v-list-item-subtitle>
                                                </v-col>
                                                <v-col cols="8"> </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-navigation-drawer>

                                <v-data-table
                                    style="background-color: transparent"
                                    dark
                                    class="org-data-table"
                                    :items="currentLevel.admins"
                                    :headers="userHeaders"
                                    disable-sort
                                    no-data-text="No admins under this organization"
                                >
                                    <template v-slot:item.email="{ item }">
                                        <span v-if="item.account">{{
                                            item.account.email
                                        }}</span>
                                        <span v-if="!item.account">{{
                                            item.requestedEmail
                                        }}</span>
                                        <v-chip
                                            class="ma-2"
                                            color="orange"
                                            label
                                            small
                                        >
                                            Admin
                                            <v-icon>{{
                                                mdiAccountSupervisor
                                            }}</v-icon>
                                        </v-chip>
                                        <v-chip
                                            v-if="!item.account"
                                            class="ma-2"
                                            color="gray"
                                            label
                                            small
                                        >
                                            Pending
                                        </v-chip>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
        <!-- <div v-if="currentView === 'Tree View'" class="org-container">
            <tree-view
                :current-level="currentLevel"
                :user-accounts="filterNormalUser(currentLevel.accounts)"
                :base-level-id="baseLevelId"
                @update="updateData($event)"
                @change="changeOrg($event)"
                @deleted="deletedOrg($event)"
            ></tree-view>
        </div> -->
        <v-snackbar v-model="snackbar">
            {{ snackbarMessage }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    color="white"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import { navLinks } from '../navigation.js'
import { mdiArrowLeft, mdiAccountSupervisor } from '@mdi/js'
import CreateSubOrg from '../../../components/branches/create_sub_org.vue'
import PrintOrgAddress from '../../../components/branches/print_address.vue'
import UpdateOrg from '../../../components/branches/update_org.vue'
import AddUser from '../../../components/branches/add_user.vue'
import UpdateUser from '../../../components/branches/update_user.vue'
import RemovePending from '../../../components/branches/remove_pending.vue'
import gql from 'graphql-tag'

export default {
    name: `Branches`,
    components: {
        CreateSubOrg,
        UpdateOrg,
        AddUser,
        UpdateUser,
        RemovePending,
        PrintOrgAddress
    },
    metaInfo: { title: `Your Account`, subTitle: `Branches` },
    apollo: {
        resolveAccountOrganization: {
            variables() {
                return {
                    resolveAccountOrganizationId:
                        this.currentLevel.baseAccountId
                }
            },
            skip() {
                return !this.currentLevel.baseAccountId
            },
            query: gql`
                query ResolveAccountOrganization(
                    $resolveAccountOrganizationId: String
                ) {
                    resolveAccountOrganization(
                        id: $resolveAccountOrganizationId
                    ) {
                        accounts {
                            account {
                                email
                            }
                            accountId
                            admin
                            requestedEmail
                        }
                        address {
                            city
                            country
                            line1
                            line2
                            postalCode
                            state
                        }
                        orgId
                        enterprise
                        parentOrgId
                        parentOrg {
                            orgId
                            organizationName
                        }
                        organizationName
                        createdByAccountId
                        admins {
                            account {
                                email
                                id
                            }
                            accountId
                            admin
                            requestedEmail
                        }
                        childrenOrg {
                            orgId
                            organizationName
                            address {
                                city
                                country
                                line1
                                line2
                                postalCode
                                state
                            }
                        }
                    }
                }
            `,
            update(data) {
                this.currentLevel = this.currentLevelObj(
                    data.resolveAccountOrganization
                )
            }
        }
    },

    data() {
        return {
            mdiArrowLeft,
            links: navLinks,
            mdiAccountSupervisor,
            panel: 0,
            loading: true,
            tableView: true,
            snackbar: false,
            viewOptions: ['Table View', 'Tree View'],
            currentView: 'Table View',
            snackbarMessage: '',
            userId: this.$auth.user.id,
            baseLevelId: '',
            noBranches: false,
            currentLevel: {
                baseAccountId: null,
                baseAccountName: null,
                childrenOrg: [],
                parentOrgId: null,
                parentOrgName: null,
                accounts: [],
                admins: [],
                address: {
                    line1: '',
                    line2: '',
                    city: '',
                    state: '',
                    country: '',
                    postalCode: ''
                }
            },
            subOrgHeaders: [
                {
                    text: 'Sub-Organization Name',
                    align: 'start',
                    sortable: false,
                    value: 'organizationName'
                },
                {
                    text: 'Address',
                    align: 'start',
                    sortable: false,
                    value: 'address'
                },
                {
                    text: '',
                    align: 'end',
                    sortable: false,
                    value: 'action'
                }
            ],
            userHeaders: [
                {
                    text: 'Account Email',
                    align: 'start',
                    sortable: false,
                    value: 'email'
                },
                {
                    text: '',
                    align: 'end',
                    sortable: false,
                    value: 'action'
                }
            ]
        }
    },
    created() {
        this.$apollo
            .query({
                query: gql`
                    query ResolveBaseAccountOrganization {
                        resolveBaseAccountOrganization {
                            accounts {
                                account {
                                    email
                                }
                                accountId
                                admin
                                requestedEmail
                            }
                            parentOrgId
                            orgId
                            organizationName
                            admins {
                                account {
                                    email
                                    id
                                }
                                accountId
                                admin
                                requestedEmail
                            }
                        }
                    }
                `
            })
            .then((data) => {
                if (data.data.resolveBaseAccountOrganization !== null) {
                    const resolveBase = data.data.resolveBaseAccountOrganization
                    this.currentLevel.baseAccountId = resolveBase.orgId
                    this.baseLevelId = resolveBase.orgId
                    this.loading = false
                } else {
                    this.noBranches = true
                    this.loading = false
                }
            })
    },
    methods: {
        updateData(status) {
            this.$apollo.queries.resolveAccountOrganization.fetchMore({})
            this.snackbar = true
            this.snackbarMessage = status
        },
        async deletedOrg(obj) {
            await this.$apollo.queries.resolveAccountOrganization
                .fetchMore({
                    variables: {
                        resolveAccountOrganizationId: obj.parentId
                    }
                })
                .then((data) => {
                    this.currentLevel.baseAccountId =
                        data.data.resolveAccountOrganization.orgId
                    this.snackbar = true
                    this.snackbarMessage = obj.status
                })
        },
        currentLevelObj(data) {
            return {
                baseAccountId: data.orgId,
                baseAccountName: data.organizationName,
                enterprise: data.enterprise || false,
                childrenOrg: data.childrenOrg,
                parentOrgId: data.parentOrgId,
                parentOrgName: data.parentOrg
                    ? data.parentOrg.organizationName
                    : null,
                accounts: data.accounts,
                admins: data.admins,
                address: data.address
            }
        },
        changeOrg(org) {
            this.currentLevel.baseAccountId = org
        },
        checkAdmin(level) {
            const account = level.admins.find(
                (e) => e.accountId === this.$auth.user.id
            )
            if (account) {
                return true
            } else {
                return false
            }
        },
        filterAdmins(accounts) {
            const admins = accounts.filter((e) => e.admin === true)
            return admins
        },
        filterNormalUser(accounts) {
            const user = accounts.filter((e) => e.admin === false)
            return user
        },
        removePendingUser(account) {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation RemoveUserFromOrganization(
                            $orgId: String!
                            $email: String
                            $userId: String
                        ) {
                            removeUserFromOrganization(
                                orgId: $orgId
                                email: $email
                                userId: $userId
                            ) {
                                complete
                                error
                                success
                                uid
                            }
                        }
                    `,
                    variables: {
                        orgId: this.currentLevel.baseAccountId,
                        email: account.requestedEmail
                    }
                })
                .then((data) => {
                    if (data.removeUserFromOrganization.success === true) {
                        this.snackbar = true
                        this.snackbarMessage = `'${account.requestedEmail}' successfully removed`
                    } else {
                        this.snackbar = true
                        this.snackbarMessage = `Error removing '${account.requestedEmail}'`
                    }
                })
        }
    }
}
</script>

<style>
.expansion-header {
    margin: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0)
    );
}
.expansion-header-first {
    margin: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0)
    );
}
.expanded-panels .v-expansion-panel-content__wrap {
    padding: 0px;
}
</style>
