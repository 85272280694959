<template>
    <v-dialog v-model="dialog" max-width="700">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="tableView"
                color="primary"
                elevation="0"
                outlined
                small
                v-bind="attrs"
                v-on="on"
                >Create</v-btn
            >
            <v-btn
                v-if="!tableView"
                v-bind="attrs"
                color="primary"
                elevation="0"
                style="display: block; margin: auto; min-width: 150px"
                v-on="on"
            >
                Add Branch
            </v-btn>
        </template>
        <div
            class="container-bg"
            style="
                margin: auto;
                display: block;
                background: rgba(20, 20, 20, 0.8);
            "
        >
            <div class="container-fg">
                <v-card
                    dark
                    style="background-color: transparent"
                    elevation="0"
                >
                    <v-card-title style="background-color: transparent">
                        <h1
                            style="
                                font-size: 36px;
                                letter-spacing: -0.08rem;
                                color: white;
                            "
                        >
                            Create New Organization
                        </h1>
                    </v-card-title>
                    <v-divider
                        style="margin-bottom: 25px"
                        color="gray"
                    ></v-divider>
                    <v-row v-if="noParentOrg === false">
                        <v-col cols="6">
                            <v-text-field
                                outlined
                                label="Create From"
                                dense
                                :value="currentLevel.baseAccountName"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="orgName"
                                outlined
                                dense
                                label="Organization Name"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="noParentOrg === true">
                        <v-col cols="6">
                            <v-text-field
                                v-model="orgName"
                                outlined
                                label="Organization Name"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <org-address
                        :current-level="currentLevel"
                        :org-name="orgName"
                        :view="'create'"
                        @address="address = $event"
                        @valid="addressValid = $event"
                    >
                    </org-address>

                    <div>
                        <base-btn
                            color="primary"
                            outlined
                            @click="dialog = false"
                        >
                            Close
                        </base-btn>
                        <base-btn
                            class="elevation-0 dialog-btns"
                            style="float: right"
                            :disabled="!canSubmit"
                            @click="createSub"
                        >
                            Create
                        </base-btn>
                    </div>
                </v-card>
            </div>
        </div>
        <v-snackbar v-model="snackbar">
            {{ snackbarMessage }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    color="white"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-dialog>
</template>

<script>
import gql from 'graphql-tag'
import OrgAddress from './address.vue'

export default {
    name: 'CreateSubOrg',
    components: { OrgAddress },
    props: {
        currentLevel: {
            type: Object,
            required: true
        },
        tableView: {
            type: Boolean,
            required: true
        },
        noParentOrg: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            dialog: false,
            orgName: '',
            address: null,
            addressValid: false,
            snackbar: false,
            snackbarMessage: ''
        }
    },
    computed: {
        canSubmit() {
            return this.orgName && this.addressValid
        }
    },
    methods: {
        createSub() {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation CreateAccountOrganization(
                            $input: AccountOrganizationInput!
                        ) {
                            createAccountOrganization(input: $input) {
                                complete
                                error
                                success
                                uid
                            }
                        }
                    `,
                    variables: {
                        input: {
                            organizationName: this.orgName,
                            parentOrgId: this.currentLevel.baseAccountId,
                            address: this.address
                        }
                    }
                })
                .then((data) => {
                    if (data.data.createAccountOrganization.success === true) {
                        this.$emit(
                            'created',
                            `'${this.orgName}' successfully created`
                        )
                        this.dialog = false
                    } else {
                        this.snackbar = true
                        this.snackbarMessage = `Error creating '${this.orgName}'`
                    }
                })
        }
    }
}
</script>
