<template>
    <div style="display: block; margin: auto">
        <v-dialog v-model="dialog" max-width="700">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-if="tableView"
                    color="primary"
                    elevation="0"
                    outlined
                    small
                    v-bind="attrs"
                    v-on="on"
                    >Update Organization</v-btn
                >
                <v-btn
                    v-if="!tableView && !deleteOnly"
                    color="primary"
                    elevation="0"
                    v-bind="attrs"
                    style="display: block; margin: auto; min-width: 150px"
                    v-on="on"
                >
                    Update {{ currentLevel.baseAccountName }}
                </v-btn>
                <v-btn
                    v-if="!tableView && deleteOnly"
                    color="primary"
                    outlined
                    elevation="0"
                    style="display: block; margin: auto; min-width: 150px"
                    @click="confirmDialog = true"
                >
                    Delete {{ currentLevel.baseAccountName }}
                </v-btn>
            </template>
            <div
                class="container-bg"
                style="
                    margin: auto;
                    display: block;
                    background: rgba(20, 20, 20, 0.8);
                "
            >
                <div class="container-fg">
                    <v-card
                        dark
                        style="background-color: transparent"
                        elevation="0"
                    >
                        <v-card-title
                            style="
                                background-color: transparent;
                                justify-content: space-between;
                            "
                        >
                            <h1
                                style="
                                    font-size: 36px;
                                    letter-spacing: -0.08rem;
                                    color: white;
                                "
                            >
                                Update Organization
                            </h1>
                            <base-btn
                                v-if="tableView"
                                class="elevation-0 dialog-btns"
                                outlined
                                style="float: right"
                                @click="confirmDialog = true"
                            >
                                Delete {{ currentLevel.baseAccountName }}
                            </base-btn>
                        </v-card-title>
                        <v-divider
                            style="margin-bottom: 25px"
                            color="gray"
                        ></v-divider>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    outlined
                                    label="Current Organization Name"
                                    dense
                                    :value="currentLevel.baseAccountName"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="orgName"
                                    dense
                                    outlined
                                    label="New Organization Name"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <org-address
                            :current-level="currentLevel"
                            :view="'update'"
                            @address="address = $event"
                            @valid="addressValid = $event"
                        >
                        </org-address>
                        <div>
                            <base-btn
                                color="primary"
                                outlined
                                @click="dialog = false"
                            >
                                Close
                            </base-btn>
                            <base-btn
                                class="elevation-0 dialog-btns"
                                style="float: right"
                                :disabled="!addressValid"
                                @click="updateOrg"
                            >
                                Update
                            </base-btn>
                        </div>
                    </v-card>
                </div>
            </div>

            <v-snackbar v-model="snackbar">
                {{ snackbarMessage }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        color="white"
                        @click="snackbar = false"
                    >
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </v-dialog>
        <v-dialog v-model="confirmDialog" width="700">
            <div class="container-bg">
                <div class="container-fg">
                    <v-card
                        style="background-color: transparent"
                        elevation="0"
                        dark
                    >
                        <v-card-title dark>
                            <h1
                                style="
                                    font-size: 36px;
                                    letter-spacing: -0.08rem;
                                    color: white;
                                "
                            >
                                Are you sure you want to delete <br />
                                {{ currentLevel.baseAccountName }}?
                            </h1>
                        </v-card-title>

                        <v-divider></v-divider>

                        <v-card-text>
                            You will not be able to undo this action. <br />
                            To confirm, please type in the organization name "{{
                                currentLevel.baseAccountName
                            }}" in the box below. <br />
                            <v-text-field
                                v-model="confirmDelete"
                                style="margin-top: 15px; margin-bottom: -15px"
                                outlined
                                label="Organization Name"
                            ></v-text-field>
                        </v-card-text>

                        <div>
                            <base-btn
                                color="primary"
                                outlined
                                @click="
                                    dialog = false
                                    confirmDialog = false
                                "
                            >
                                Cancel
                            </base-btn>
                            <base-btn
                                class="elevation-0 dialog-btns"
                                style="float: right"
                                :disabled="confirmDeletion"
                                @click="deleteOrg"
                            >
                                Delete {{ currentLevel.baseAccountName }}
                            </base-btn>
                        </div>
                    </v-card>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
import gql from 'graphql-tag'
import OrgAddress from './address.vue'

export default {
    name: 'UpdateOrg',
    components: { OrgAddress },
    props: {
        currentLevel: {
            type: Object,
            required: true
        },
        tableView: {
            type: Boolean,
            required: true
        },
        deleteOnly: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            dialog: false,
            confirmDialog: false,
            confirmDelete: '',
            orgName: '',
            address: null,
            addressValid: false,
            snackbar: false,
            snackbarMessage: ''
        }
    },
    computed: {
        confirmDeletion() {
            if (this.confirmDelete === this.currentLevel.baseAccountName) {
                return false
            } else {
                return true
            }
        }
    },
    methods: {
        updateOrg() {
            if (this.orgName === '') {
                this.orgName = this.currentLevel.baseAccountName
            }
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateAccountOrganization(
                            $input: AccountOrganizationInput!
                            $orgId: String!
                        ) {
                            updateAccountOrganization(
                                input: $input
                                orgId: $orgId
                            ) {
                                complete
                                error
                                success
                                uid
                            }
                        }
                    `,
                    variables: {
                        input: {
                            organizationName: this.orgName,
                            parentOrgId: this.currentLevel.parentOrgId,
                            address: this.address
                        },
                        orgId: this.currentLevel.baseAccountId
                    }
                })
                .then((data) => {
                    if (data.data.updateAccountOrganization.success === true) {
                        this.$emit(
                            'updated',
                            `'${this.orgName}' successfully updated`
                        )
                        this.orgName = ''
                        this.dialog = false
                    } else {
                        this.snackbar = true
                        this.snackbarMessage = `Error updating '${this.orgName}'`
                    }
                })
                .catch(() => {
                    this.snackbar = true
                    this.snackbarMessage = `Error updating '${this.orgName}'`
                })
        },
        deleteOrg() {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation DisableAccountOrganization($orgId: String!) {
                            disableAccountOrganization(orgId: $orgId) {
                                complete
                                error
                                success
                                uid
                            }
                        }
                    `,
                    variables: {
                        orgId: this.currentLevel.baseAccountId
                    }
                })
                .then((data) => {
                    if (data.data.disableAccountOrganization.success === true) {
                        this.$emit('deleted', {
                            parentId: this.currentLevel.parentOrgId,
                            status: `'${this.currentLevel.baseAccountName}' successfully deleted`
                        })
                        this.confirmDelete = ''
                        this.dialog = false
                        this.confirmDialog = false
                    } else {
                        this.snackbar = true
                        this.snackbarMessage = `Error deleting '${this.currentLevel.baseAccountName}'`
                    }
                })
        }
    }
}
</script>
