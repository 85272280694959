var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('base-hero-simple',{staticClass:"mb-2"},[_c('base-router-links',{attrs:{"items":_vm.links}})],1),_c('v-overlay',{attrs:{"absolute":true,"value":_vm.loading || _vm.$apollo.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1),_c('v-overlay',{attrs:{"absolute":true,"opacity":"1","value":_vm.noBranches}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","text-align":"center"}},[_c('h2',[_vm._v("It looks like you've reached this page by accident. Please click "),_c('a',{on:{"click":function($event){return _vm.$router.push({ name: 'home' })}}},[_vm._v("here")]),_vm._v(" to go back to the homepage.")])])]),_c('div',{staticClass:"container-bg",staticStyle:{"margin":"auto"}},[_c('div',{staticClass:"container-fg"},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('img',{staticStyle:{"width":"130px","margin-top":"10px"},attrs:{"src":require("../../../assets/logos/getac-logo.svg")}}),_c('div',{staticClass:"text-h7 nav-font pl-3",staticStyle:{"margin-top":"22px"}},[_vm._v("ACCOUNT ORGANIZATIONS")])])]),_c('v-col',{attrs:{"cols":"2"}})],1)],1),_c('v-divider',{staticStyle:{"margin-bottom":"1%","border-color":"gray"}}),(_vm.currentView === 'Table View')?_c('div',{staticClass:"org-container"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{staticClass:"expanded-panels",attrs:{"dark":"","accordion":"","mandatory":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"expansion-header-first",attrs:{"expand-icon":""}},[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"4"}},[(
                                                _vm.currentLevel.parentOrgId &&
                                                _vm.baseLevelId !==
                                                    _vm.currentLevel.baseAccountId
                                            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({on:{"click":function($event){_vm.currentLevel.baseAccountId =
                                                            _vm.currentLevel.parentOrgId}}},'div',attrs,false),on),[_c('v-list-item-subtitle',{staticStyle:{"color":"gray","margin-bottom":"10px"}},[_vm._v(" Previous Branch ")]),_c('v-icon',{attrs:{"color":"#db5120"}},[_vm._v(_vm._s(_vm.mdiArrowLeft))])],1)]}}],null,false,1007972829)},[_c('span',[_vm._v(_vm._s(_vm.currentLevel.parentOrgName)+" ")])]):_vm._e()],1),_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"4"}},[_c('v-list-item-subtitle',{staticStyle:{"color":"gray","margin-bottom":"5px"}},[_vm._v(" Organization ")]),_c('h1',{staticStyle:{"font-size":"36px","letter-spacing":"-0.01rem"}},[_vm._v(" "+_vm._s(_vm.currentLevel.baseAccountName)+" ")]),(_vm.currentLevel.address)?_c('v-list-item-subtitle',{staticClass:"mt-2",staticStyle:{"color":"gray","margin-bottom":"5px"}},[_c('print-org-address',{attrs:{"address":_vm.currentLevel.address}})],1):_vm._e()],1),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"4"}},[(_vm.checkAdmin(_vm.currentLevel))?_c('update-org',{attrs:{"current-level":_vm.currentLevel,"table-view":_vm.tableView,"delete-only":false},on:{"updated":_vm.updateData,"deleted":function($event){return _vm.deletedOrg($event)}}}):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-row',[(
                            _vm.checkAdmin(_vm.currentLevel) &&
                            _vm.currentLevel.enterprise
                        )?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"org-users-section"},[_c('v-navigation-drawer',{attrs:{"width":"100%","dark":"","permanent":"","floating":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-list-item-subtitle',{staticStyle:{"margin-top":"7px"}},[_vm._v(" Organizations Under "+_vm._s(_vm.currentLevel.baseAccountName)+" ")])],1),_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"4"}},[_c('create-sub-org',{attrs:{"no-parent-org":false,"current-level":_vm.currentLevel,"table-view":_vm.tableView},on:{"created":_vm.updateData}})],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"org-data-table",staticStyle:{"background-color":"transparent"},attrs:{"dark":"","items":_vm.currentLevel.childrenOrg,"hide-default-footer":"","no-data-text":("No sub-organizations exist under " + (_vm.currentLevel.baseAccountName)),"headers":_vm.subOrgHeaders,"disable-sort":""},scopedSlots:_vm._u([{key:"item.address",fn:function(ref){
                        var item = ref.item;
return [(item.address)?_c('div',[_c('print-org-address',{attrs:{"address":item.address}})],1):_vm._e()]}},{key:"item.action",fn:function(ref){
                        var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","elevation":"0","outlined":"","small":""},on:{"click":function($event){_vm.currentLevel.baseAccountId =
                                                item.orgId}}},[_vm._v("View")])]}}],null,false,3419077560)})],1)]):_vm._e(),_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"org-users-section"},[_c('v-navigation-drawer',{attrs:{"width":"100%","dark":"","permanent":"","floating":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-list-item-subtitle',{staticStyle:{"margin-top":"7px"}},[_vm._v(" User Accounts ")])],1),_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"4"}},[(
                                                        _vm.checkAdmin(
                                                            _vm.currentLevel
                                                        )
                                                    )?_c('add-user',{attrs:{"current-level":_vm.currentLevel,"table-view":_vm.tableView},on:{"added":_vm.updateData}}):_vm._e()],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"org-data-table",staticStyle:{"background-color":"transparent"},attrs:{"dark":"","no-data-text":"No accounts have been added under this organization","items":_vm.currentLevel.accounts,"headers":_vm.userHeaders,"disable-sort":""},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
                                                    var item = ref.item;
return [(item.account)?_c('span',[_vm._v(_vm._s(item.account.email))]):_vm._e(),(!item.account)?_c('span',[_vm._v(" "+_vm._s(item.requestedEmail))]):_vm._e(),(item.admin)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"orange","label":"","small":""}},[_vm._v(" Admin "),_c('v-icon',[_vm._v(_vm._s(_vm.mdiAccountSupervisor))])],1):_vm._e(),(!item.account)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"gray","label":"","small":""}},[_vm._v(" Pending ")]):_vm._e()]}},{key:"pending",fn:function(ref){
                                                    var item = ref.item;
return undefined}},{key:"item.action",fn:function(ref){
                                                    var item = ref.item;
return [(
                                            item.account &&
                                            _vm.checkAdmin(_vm.currentLevel)
                                        )?_c('update-user',{attrs:{"current-level":_vm.currentLevel,"current-account":item},on:{"updated":function($event){return _vm.updateData($event)}}}):_vm._e(),(
                                            !item.account &&
                                            _vm.checkAdmin(_vm.currentLevel)
                                        )?_c('remove-pending',{attrs:{"current-level":_vm.currentLevel,"current-account":item,"table-view":_vm.tableView},on:{"deleted":function($event){return _vm.updateData($event)}}}):_vm._e()]}}],null,false,1888663269)})],1)]),_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"org-users-section"},[_c('v-navigation-drawer',{attrs:{"width":"100%","dark":"","permanent":"","floating":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-list-item-subtitle',{staticStyle:{"margin-top":"7px"}},[_vm._v(" Admins ")])],1),_c('v-col',{attrs:{"cols":"8"}})],1)],1)],1)],1),_c('v-data-table',{staticClass:"org-data-table",staticStyle:{"background-color":"transparent"},attrs:{"dark":"","items":_vm.currentLevel.admins,"headers":_vm.userHeaders,"disable-sort":"","no-data-text":"No admins under this organization"},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
                                        var item = ref.item;
return [(item.account)?_c('span',[_vm._v(_vm._s(item.account.email))]):_vm._e(),(!item.account)?_c('span',[_vm._v(_vm._s(item.requestedEmail))]):_vm._e(),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"orange","label":"","small":""}},[_vm._v(" Admin "),_c('v-icon',[_vm._v(_vm._s(_vm.mdiAccountSupervisor))])],1),(!item.account)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"gray","label":"","small":""}},[_vm._v(" Pending ")]):_vm._e()]}}],null,false,22210404)})],1)])],1)],1):_vm._e()],1)]),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
                                        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","color":"white"},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }