<template>
    <v-dialog v-model="dialog" max-width="700">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="tableView"
                color="primary"
                elevation="0"
                outlined
                small
                v-bind="attrs"
                v-on="on"
                >Add Account</v-btn
            >
            <v-btn
                v-if="!tableView"
                color="primary"
                v-bind="attrs"
                elevation="0"
                style="display: block; margin: auto; min-width: 150px"
                v-on="on"
            >
                Add User
            </v-btn>
        </template>
        <div
            class="container-bg"
            style="
                margin: auto;
                display: block;
                background: rgba(20, 20, 20, 0.8);
            "
        >
            <div class="container-fg">
                <v-card
                    dark
                    style="background-color: transparent"
                    elevation="0"
                >
                    <v-card-title style="background-color: transparent">
                        <h1
                            style="
                                font-size: 36px;
                                letter-spacing: -0.08rem;
                                margin-left: -5px;
                                color: white;
                            "
                        >
                            Add User Account to "{{
                                currentLevel.baseAccountName
                            }}""
                        </h1>
                    </v-card-title>
                    <v-divider
                        style="margin-bottom: 25px"
                        color="gray"
                    ></v-divider>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="accEmail"
                                outlined
                                label="Account Email"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-switch
                                v-model="adminSwitch"
                                color="primary"
                                inset
                                :label="`Add As Admin Account`"
                            ></v-switch>
                        </v-col>
                    </v-row>

                    <div>
                        <base-btn
                            color="primary"
                            outlined
                            @click="dialog = false"
                        >
                            Close
                        </base-btn>
                        <base-btn
                            class="elevation-0 dialog-btns"
                            style="float: right"
                            :disabled="!accEmail"
                            @click="inviteUser"
                        >
                            Add
                        </base-btn>
                    </div>
                </v-card>
            </div>
        </div>
        <v-snackbar v-model="snackbar">
            {{ snackbarMessage }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    color="white"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-dialog>
</template>

<script>
import gql from 'graphql-tag'

export default {
    name: 'AddUser',
    props: {
        currentLevel: {
            type: Object,
            required: true
        },
        tableView: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            dialog: false,
            accEmail: '',
            adminSwitch: false,
            snackbar: false,
            snackbarMessage: ''
        }
    },
    methods: {
        inviteUser() {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation AddUserToOrganization(
                            $admin: Boolean!
                            $email: String!
                            $orgId: String!
                        ) {
                            addUserToOrganization(
                                admin: $admin
                                email: $email
                                orgId: $orgId
                            ) {
                                complete
                                error
                                success
                                uid
                            }
                        }
                    `,
                    variables: {
                        admin: this.adminSwitch,
                        email: this.accEmail,
                        orgId: this.currentLevel.baseAccountId
                    }
                })
                .then((data) => {
                    if (data.data.addUserToOrganization.success === true) {
                        this.$emit(
                            'added',
                            `'${this.accEmail}' has been invited to join ${this.currentLevel.baseAccountName}`
                        )
                        this.accEmail = ''
                        this.dialog = false
                    } else {
                        this.snackbar = true
                        this.snackbarMessage = `Error inviting '${this.accEmail}' to join ${this.currentLevel.baseAccountName}`
                    }
                })
        }
    }
}
</script>
